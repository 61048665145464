Body {
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
}

.Main {
    display: block;
    padding: 20px 0;
}

.Center {
    display: inline-block;
    margin-left: calc(50% - 80px);
}
.Center * {
    display: inline-block;
    cursor: pointer;
    outline: none;
}
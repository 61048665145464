/*--------------------------------------------------------------
## Buttons
--------------------------------------------------------------*/
.Btn {
	padding: 15px 35px;
	transition: 350ms ease-in-out;
	/* background-color: rgba(91, 159, 185, 1); */
	background-color: rgba(0, 106, 141, 1);
	color: #fff;
	font-size: 25px;
	font-weight: 300;
}

.Btn:disabled {
	cursor:not-allowed;
}

.Btn:hover {
	background-color: rgba(0, 106, 141, 0.85);
}

.BtnMulti {
	display: inline-flex;
    margin-right: 5px;
}

.BtnUpd {
	padding: 15px 35px;
	transition: 350ms ease-in-out;
	background-color: rgba(0, 106, 141, 1);
	color: #fff;
	font-size: 25px;
	font-weight: 300;
}
.BtnDel {
	padding: 15px 35px;
	transition: 350ms ease-in-out;
	background-color: rgba(0, 106, 141, 1);
	color: #fff;
	font-size: 25px;
	font-weight: 300;
}

.Pointer {
	cursor: pointer;
}

.BtnIcon {
	font-size: 2.6rem;
	filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(99deg) brightness(113%) contrast(103%) drop-shadow(1px 1px 1px #666);
	display: inline-block;
	cursor: pointer;
}

.BtnIconLeft {
	float:left;
}
.BtnIconRight {
	float:right;
	
}

.BtnIcon .Icon {
	width: 45px;
	height: 45px;
	transform: scale(1);
	backface-visibility: hidden;
	transition: 350ms ease-in-out;
}

.BtnIcon .Icon:hover {
	transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
	.Btn {
		padding: 15px 25px;
		font-size: 18px;
	}
}
/*--------------------------------------------------------------
## Form -> INPUT
--------------------------------------------------------------*/
.FieldWrap {
	margin: 20px 0 40px;
}

.Field {
	display: block;
	box-sizing: border-box;
	width: 100%;
	padding: 10px;
	border: 1px solid #999;
	color: #999;
	font-size: 20px;
}
.Label {
	display: block;
	color: #999;
	padding-bottom: 5px;
	text-transform: uppercase;
}

.Invalid {
    border: 1px solid red;
    background-color: #FDA49A;
}

.Half {
	width: calc(50% - 2px);
	display: inline-grid;
	margin-right: 2px;
}

@media only screen and (max-width: 600px) {
	.FieldWrap {
		margin: 15px 0 20px;
	}

	.Field {
		padding: 10px;
		font-size: 20px;
	}
}

@media only screen and (max-width: 414px) {
	.Half {
		width: 100%;
		display: block;
		margin-right: 0;
	}
}

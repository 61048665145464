/* -------------- VEGA TMP ---------------- */
/*--------------------------------------------------------------
## Fonts
--------------------------------------------------------------*/
@font-face {
	font-family: 'Roboto';
	font-weight: normal;
	src: url('./assets/fonts/Roboto-Regular.woff2') format('woff2'),
		url('./assets/fonts/Roboto-Regular.woff') format('woff');
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	font-weight: 300;
	src: url('./assets/fonts/Roboto-Light.woff2') format('woff2'),
		url('./assets/fonts/Roboto-Light.woff') format('woff');
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	font-weight: 100;
	src: url('./assets/fonts/Roboto-Thin.woff2') format('woff2'),
		url('./assets/fonts/Roboto-Thin.woff') format('woff');
	font-style: normal;
}

/*--------------------------------------------------------------
## Main
--------------------------------------------------------------*/
body {
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
	background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3E%3Cg fill='%23c7c7c7' fill-opacity='0.41'%3E%3Cpath fill-rule='evenodd' d='M0 0h4v4H0V0zm4 4h4v4H4V4z'/%3E%3C/g%3E%3C/svg%3E");
}

.page-wrap {
	min-height: 100vh;
	flex: 1;
	display: flex;
	flex-direction: column;
}

.wrap {
	position: relative;
	width: 100%;
	max-width: 1024px;
	margin: 0 auto;
	padding: 0 30px;
}

.align-right {
	text-align: right;
}

@media only screen and (max-width: 600px) {
	.wrap {
		padding: 0 15px;
	}
}

/*--------------------------------------------------------------
## Reset
--------------------------------------------------------------*/
body,
div {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}

button,
input {
	border: 0;
	background: transparent;
	font-family: 'Roboto', sans-serif;
	font-weight: 300;
	cursor: pointer;
}

button:active,
button:focus,
input:focus {
	outline: none;
}

/*--------------------------------------------------------------
## Checkflag Item
--------------------------------------------------------------*/
.item-row {
	margin: 20px 0;
	position: relative;
}

.check-flag {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 40px 30px 30px;
	transition: 350ms ease-in-out;
	box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}

.small-text-label {
	position: absolute;
	top: 10px;
	color: #999;
	text-transform: uppercase;
	font-size: 11px;
}

.small-text-label.hours {
	right: 37px;
}

.check-flag:hover {
	background-color: #f9f9f9;
}

.check-flag-label {
	color: #666;
	font-size: 25px;
	font-weight: 300;
}

.hours-box {
	width: 50px;
	height: 20px;
	margin-top: 10px;
	border-bottom: 1px solid #999;
}

.checkmark {
	display: block;
	position: relative;
	flex-shrink: 0;
	width: 18px;
	height: 18px;
	padding: 3px;
	transition: 350ms ease-in-out;
	border: 2px solid #999;
	border-radius: 3px;
}

.checkmark .checkmark-icon {
	transition: 350ms ease-in-out;
	stroke: white;
	stroke-width: 3px;
	stroke-dashoffset: 30;
	stroke-dasharray: 30;
}

@media only screen and (max-width: 600px) {
	.check-flag {
		padding: 30px 20px 20px;
	}

	.check-flag-label {
		font-size: 15px;
	}

	.small-text-label.hours {
		right: 25px;
	}
}
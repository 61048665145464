/*--------------------------------------------------------------
## Footer
--------------------------------------------------------------*/
.Footer {
	margin-top: auto;
	padding: 20px 0;
	background-color: #333;
	text-align: center;
}

.FooterCopy {
	display: block;
	color: #fff;
	font-size: 15px;
	font-weight: 300;
	padding-bottom: 5px;
}

.FooterCopy a {
	padding: 0 5px;
	font-weight: bold;
	color: rgba(0, 106, 141, 1);
	text-decoration: underline;
}

.Static {
	position: static !important;
}
.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 99;
    left: 0;
    top: 0;
    transition-property: 'all';
    transition-duration: 350ms;
    transition-timing-function: 'ease-in-out';
    transition-delay: 0s;
}
.HeaderBlockquote {
	/* max-width: 520px; */
	margin-left: auto;
	padding: 80px 0;
	text-align: right;
}

.HeaderQuote {
	margin: 0;
	padding: 0 15px;
	/* margin-bottom: 0.5em; */
	color: #fff;
	filter: drop-shadow(1px 1px 1px #666);
	font-size: 2.7rem;
	font-weight: 300;
	background-color: rgba(0,0,0,0.40)
}

.HeaderCite {
	color: #fff;
	font-size: 30px;
	font-weight: 100;
	margin-top: 1px;
	background-color: rgba(0,0,0,0.40);
	padding-right:15px;
}
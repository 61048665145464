.Header {
	padding-top: 40px;
	background: #5B9FB9 url('../../assets/images/header-bg.jpg') no-repeat left top;
	background-size: cover !important; 
}
.bgrnd1 {
	background: #5B9FB9 url('../../assets/images/header-bg1.jpg') no-repeat left top;
}
.bgrnd2 {
	background: #5B9FB9 url('../../assets/images/header-bg2.jpg') no-repeat left top;
}
.bgrnd3 {
	background: #5B9FB9 url('../../assets/images/header-bg3.jpg') no-repeat left top;
}
.bgrnd4 {
	background: #5B9FB9 url('../../assets/images/header-bg4.jpg') no-repeat left top;
}
.bgrnd5 {
	background: #5B9FB9 url('../../assets/images/header-bg5.jpg') no-repeat left top;
}
.bgrnd6 {
	background: #5B9FB9 url('../../assets/images/header-bg6.jpg') no-repeat left top;
}
.bgrnd7 {
	background: #5B9FB9 url('../../assets/images/header-bg7.jpg') no-repeat left top;
}
.bgrnd8 {
	background: #5B9FB9 url('../../assets/images/header-bg8.jpg') no-repeat left top;
}
.bgrnd9 {
	background: #5B9FB9 url('../../assets/images/header-bg9.jpg') no-repeat left top;
}
.bgrnd10 {
	background: #5B9FB9 url('../../assets/images/header-bg10.jpg') no-repeat left top;
}
.bgrnd11 {
	background: #5B9FB9 url('../../assets/images/header-bg11.jpg') no-repeat left top;
}
.bgrnd12 {
	background: #5B9FB9 url('../../assets/images/header-bg12.jpg') no-repeat left top;
}
.bgrnd13 {
	background: #5B9FB9 url('../../assets/images/header-bg13.jpg') no-repeat left top;
}
.bgrnd14 {
	background: #5B9FB9 url('../../assets/images/header-bg14.jpg') no-repeat left top;
}
.bgrnd15 {
	background: #5B9FB9 url('../../assets/images/header-bg15.jpg') no-repeat left top;
}
.bgrnd16 {
	background: #5B9FB9 url('../../assets/images/header-bg16.jpg') no-repeat left top;
}
.bgrnd17 {
	background: #5B9FB9 url('../../assets/images/header-bg17.jpg') no-repeat left top;
}
.bgrnd18 {
	background: #5B9FB9 url('../../assets/images/header-bg18.jpg') no-repeat left top;
}
.bgrnd19 {
	background: #5B9FB9 url('../../assets/images/header-bg19.jpg') no-repeat left top;
}
.bgrnd20 {
	background: #5B9FB9 url('../../assets/images/header-bg20.jpg') no-repeat left top;
}
.bgrnd21 {
	background: #5B9FB9 url('../../assets/images/header-bg21.jpg') no-repeat left top;
}
.bgrnd22 {
	background: #5B9FB9 url('../../assets/images/header-bg22.jpg') no-repeat left top;
}
.bgrnd23 {
	background: #5B9FB9 url('../../assets/images/header-bg23.jpg') no-repeat left top;
}
.bgrnd24 {
	background: #5B9FB9 url('../../assets/images/header-bg24.jpg') no-repeat left top;
}

.HeaderInner {
	/* background-color: rgba(211, 67, 27, 0.5); */
	background-color: rgba(91, 159, 185, 0.5);
}

.HeaderInnerLogo {
	position: absolute;
	top: 0;
	right: 100px;
	height: 100%;
	opacity: 0.1;
}

@media only screen and (max-width: 600px) {
	.headerQuote {
		font-size: 30px;
	}

	.headerCite {
		font-size: 25px;
	}

	.headerInner .logo {
		right: 20px;
	}
}
.Main {
	display: block;
	padding: 20px 0;
}
.container{
    display:flex;
    justify-content:center;
}
.Wrap {
    position: relative;
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 30px;
}
@media only screen and (max-width: 1024px) {
    .container {
        display: block;
    }
}
.container div{
    background-color: #ffffff;
    padding: 10px !important;
    margin-right: 5px;
    margin-bottom: 10px;
    border-right: darkgray dashed 1px;
}

.home {
    padding: 20px 0 10px;
    color: #2b2b2b;
}

.H3 {
    margin-bottom: 1.75rem;
}
.paragraph {
    margin-bottom: 0.4rem;
}

.highlighted {
    color:#2b2b2b;
    font-weight: 600;
}
.box1 {
    flex-grow:1;
    flex-basis: 0;
}
.box2 {
    flex-grow:1;
    flex-basis: 0;
}
.box3 {
    flex-grow:1;
    flex-basis: 0;
}
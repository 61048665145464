/*--------------------------------------------------------------
## Modal
--------------------------------------------------------------*/
.ModalWrap {
	display: flex;
	visibility: hidden;
	position: fixed;
	top: 0;
	left: 0;
	/* align-items: center; */
	justify-content: center;
	width: 100%;
	height: 100%;
	transition: 350ms ease-in-out;
	opacity: 0;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 999;
}

.Modal {
    position: relative;
    z-index: 100;
	width: 100%;
	max-width: 700px;
	padding: 50px 40px;
	background-color: #fff;
	box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.2);
}

@media only screen and (min-height:320px) {
	.Modal {
		max-height: inherit;
    	overflow: auto;
	}
}

.Modal h2 {
	margin-top: 0;
	margin-bottom: 1em;
	color: #666;
	font-size: 30px;
	font-weight: 300;
}

.ModalWrapIsVisible {
	visibility: visible;
	opacity: 1;
}

.Close {
	float: right;
	font-size: xx-large;
	display: table-row;
}

.Pointer {
	cursor: pointer;
}

@media only screen and (max-width: 737px) {
	.Modal {
		padding: 30px 20px;
	}

	.Modal h2 {
		font-size: 20px;
	}
	.Close {
		font-size: medium;
		margin-top: 5px;
	}
}
.pageLink {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: rgba(0, 106, 141, 1);;
    background-color: #fff;
    border: 1px solid #dee2e6;
    cursor:pointer;
}

.active .pageLink {
    color: #ffffff;
    background-color: rgba(0, 106, 141, 1);;
    border: 1px solid #dee2e6;
}
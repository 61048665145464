/*--------------------------------------------------------------
## Date
--------------------------------------------------------------*/
.DateWrap {
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	align-items: center;
	padding: 35px 0;
}

.DateWrapTime {
	display: block;
	margin-left: 40px;
	color: #fff;
	font-size: 23px;
	font-weight: 300;
}

.DateWrapIcon {
	width: 25px;
	height: 25px;
}

@media only screen and (max-width: 600px) {
	.DateWrapTime {
		margin-left: 20px;
		font-size: 20px;
	}
}

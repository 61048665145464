/*--------------------------------------------------------------
## Main
--------------------------------------------------------------*/
Body {
	background-color: #fff;
	font-family: 'Roboto', sans-serif;
	display: flex;
	flex-direction: column;
}

.Main {
	display: block;
	padding: 20px 0;
}

.Total {
	float: left;
	width: 100%;
	padding-right: 20px;
}

.TotalLabel {
	color: #999;
	font-size: 12px;
	text-transform: uppercase;
	font-weight: bold;
}

.TotalInput {
	color: #999;
	border-bottom: 1px solid #999;
	font-size: 14px;
	font-weight: bold;
	width: 50px;
	text-align: center;
}

/* Pure CSS Post Tags */

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-transition: all .1s linear 0;
    -moz-transition: all .1s linear 0;
    transition: all .1s linear 0;
}

.post {
    font-family: "Lucida Sans Unicode";
    position: relative;
    margin: 1.5em;
    padding: 10px 20px;
    background: #f8f8f8;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 5px;
    width: auto;
    border: 1px solid #e8e8e8;
    margin-bottom: 20px;
    text-align: center;
}

.calendar {
    /* top: 20%; */
    /* left: 1em; */
    padding-top: 5px;
    width: 80px;
    background: #ededef;
    background: -webkit-gradient(linear, left top, left bottom, from(#ededef), to(#ccc));
    background: -moz-linear-gradient(top, #ededef, #ccc);
    font: bold 30px/60px "Lucida Sans Unicode", Arial Black, Arial, Helvetica, sans-serif;
    text-align: center;
    color: #000;
    text-shadow: #fff 0 1px 0;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    /* position: absolute; */
    position: relative;
    -moz-box-shadow: 0 2px 2px #888;
    -webkit-box-shadow: 0 2px 2px #888;
    box-shadow: 0 2px 2px #888;
}

.calendar em {
    display: block;
    font: 12px/30px "Lucida Sans Unicode", Arial, Helvetica, sans-serif;
    color: #fff;
    text-shadow: #00365a 0 -1px 0;
    background: #04599a;
    background: -webkit-gradient(linear, left top, left bottom, from(#04599a), to(#00365a));
    background: -moz-linear-gradient(top, #04599a, #00365a);
    -moz-border-radius-bottomright: 3px;
    -webkit-border-bottom-right-radius: 3px;
    border-bottom-right-radius: 3px;
    -moz-border-radius-bottomleft: 3px;
    -webkit-border-bottom-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-top: 1px solid #e4e4e4;
}

.calendar:before,
.calendar:after {
    content: '';
    float: left;
    position: absolute;
    top: 5px;
    width: 8px;
    height: 8px;
    background: #111;
    z-index: 1;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    border-radius: 10px;
    -moz-box-shadow: 0 1px 1px #fff;
    -webkit-box-shadow: 0 1px 1px #fff;
    box-shadow: 0 1px 1px #fff;
}

.calendar:before {
    left: 11px;
}

.calendar:after {
    right: 11px;
}

.calendar em:before,
.calendar em:after {
    content: '';
    float: left;
    position: absolute;
    top: -5px;
    width: 4px;
    height: 14px;
    background: #dadada;
    background: -webkit-gradient(linear, left top, left bottom, from(#f1f1f1), to(#aaa));
    background: -moz-linear-gradient(top, #f1f1f1, #aaa);
    z-index: 2;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
}

.calendar em:before {
    left: 13px;
}

.calendar em:after {
    right: 13px;
}

@media all and (min-width: 800px) {
    .calendar {
        margin: 0 auto;
    }
}

@media all and (max-width: 800px) {
    .calendar {
        left: 1em;
        position: absolute;
    }
    @media all and (min-width: 414px) and (max-width: 568px) {
        .calendar {
            left: 0.2em;
        }
    }
}

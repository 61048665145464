body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

html, input, textarea {
  font-family: 'Roboto', sans-serif;
  line-height: 1.4;
  background: #eee;
}

body {
  margin: 0;
}

/* p {
  margin: 0;
} */

pre {
  padding: 10px;
  background-color: #eee;
  white-space: pre-wrap;
}

:not(pre)>code {
  font-family: monospace;
  background-color: #eee;
  padding: 3px;
}

img {
  max-width: 100%;
  max-height: 20em;
}

blockquote {
  border-left: 2px solid #ddd;
  margin-left: 0;
  margin-right: 0;
  padding-left: 10px;
  color: #aaa;
  font-style: italic;
}

blockquote[dir='rtl'] {
  border-left: none;
  padding-left: 0;
  padding-right: 10px;
  border-right: 2px solid #ddd;
}

table {
  border-collapse: collapse;
}

td {
  padding: 10px;
  border: 2px solid #ddd;
}

input {
  box-sizing: border-box;
  font-size: 0.85em;
  width: 100%;
  padding: 0.5em;
  border: 2px solid #ddd;
  background: #fafafa;
}

input:focus {
  outline: 0;
  border-color: blue;
}

[data-slate-editor]>*+* {
  margin-top: 1em;
}
.pattern [data-slate-editor]>*+* {
  margin-top: unset;
}

[data-slate-node]>*+* {
  margin-top: 0.05em;
}

[data-gramm] {
  padding: 4px;
  border-top: 1px solid rgb(230, 230, 230);
  border-bottom: 1px solid rgb(230, 230, 230);
  min-height: 250px;
  overflow: auto;
  max-height: 0px;
}

.pattern [data-gramm] {
  overflow: unset;
  max-height: unset;
  word-break: break-word;
}

button.MuiButton-text {
  width: 100%;
}

@media screen and (min-width:300px) {
  button.MuiButton-text {
    padding: 1px 2px;
    min-width: 25px;
  }
}

@media screen and (min-width:330px) {
  button.MuiButton-text {
    padding: 6px 8px;
    min-width: 27px;
  }
}

@media screen and (min-width:570px) {
  button.MuiButton-text {
    padding: 6px 8px;
    min-width: 55px;
  }
}

/* PAPER EDITOR BGRND */
.paper {
  position: relative;
  /* margin: 20px auto; */
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #FFFFF0;
  box-shadow: 0px 0px 5px 0px #888;
}
/* styling red vertical line */
.paper::before {
  content: '';
  width: 2px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 30px;
  background-color: rgba(255,0,0,0.2);
}

/* styling blue horizontal lines */
.pattern {
  height: 100%;
  background-image: repeating-linear-gradient(#FFFFF0 0px,#FFFFF0 24px, rgba(0,128,128,0.085) 25px);
}

/* styling text content */
.pattern [data-slate-node="element"], .pattern ol[data-slate-node="element"], .pattern blockquote[data-slate-node="element"] {
  /* padding-left: 46px;*/
  padding-right: 10px;
  margin-left:29px;
  line-height: 23px;
  /* font-family: 'Dancing Script', cursive; */
  font-size: 19px;
  letter-spacing: 1px;
  word-spacing: 5px;
  margin-top: unset;
  font-size: 1rem;
}
.pattern ol[data-slate-node="element"] {
  margin-left:15px;
}

.pattern blockquote {
  margin: 6px;
}

.pattern p {
  margin: 0px;;
}

.navbar {
  margin-top: 15px;
}

.navbar-light .navbar-nav .nav-link.active {
  font-weight: bold;
  color: rgba(0, 106, 141, 1) !important;
}
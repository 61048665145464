.ToolBar {
    min-height: 30px;
}
.DropForEdit {
    padding: 4px;
    border: 1px solid #999999;
}
.InfoIco {
    margin-right: 2px;
    color: #2196f3;
}
.Info {
    color: #0d47a1;
    font-style: italic;
    padding: 4px;
    text-align: justify;
    font-size: small;
}
.Reminder {
    display: block;
    color: #999;
    padding-bottom: 0;
    text-transform: uppercase;
}
.Half {
    width: calc(50% - 2px);
    display:inline-grid;
    margin-right: 2px;
}
@media only screen and (max-width: 414px) {
	.Half {
		width: 100%;
		display: block;
	}
}